const TOGGLE_VISUAL = "TOGGLE_VISUAL"
const CHANGE_SCALE = "CHANGE_SCALE"
const TOGGLE_ANIMATIONS = "TOGGLE_ANIMATIONS"
const SHOW_REMOVE_ICON = "SHOW_REMOVE_ICON"
const SHOW_REMOVE_ICONS = "SHOW_REMOVE_ICONS"
const CHANGE_PANEL_NAME = "CHANGE_PANEL_NAME"
const SET_TIME_OF_CREATION = "SET_TIME_OF_CREATION"
const SET_FRONT_END_REDUCER_VISUAL = "SET_FRONT_END_REDUCER_VISUAL"
const UPDATE_WARNINGS = "UPDATE_WARNINGS"
const FILTER_WARNINGS = "FILTER_WARNINGS"
const PUSH_WARNINGS = "PUSH_WARNINGS"
const UPDATE_VERSION = "UPDATE_VERSION"
const SHOW_ALERT = "SHOW_ALERT"
const REMOVE_ALERT = "REMOVE_ALERT"
const SET_ALERT_ANSWER = "SET_ALERT_ANSWER"
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"
const SET_FULL_SCREEN = "SET_FULL_SCREEN"
const SET_ALL_ICONS = "SET_ALL_ICONS"


const types = {
  TOGGLE_VISUAL,
  CHANGE_SCALE,
  TOGGLE_ANIMATIONS,
  SHOW_REMOVE_ICON,
  SHOW_REMOVE_ICONS,
  CHANGE_PANEL_NAME,
  SET_TIME_OF_CREATION,
  SET_FRONT_END_REDUCER_VISUAL,
  UPDATE_WARNINGS,
  FILTER_WARNINGS,
  PUSH_WARNINGS,
  UPDATE_VERSION,
  SHOW_ALERT,
  REMOVE_ALERT,
  SET_ALERT_ANSWER,
  CHANGE_LANGUAGE,
  SET_FULL_SCREEN,
  SET_ALL_ICONS
}
export default types