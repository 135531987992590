const ADD_PANEL = "ADD_PANEL"
const UPDATE_PANELS = "UPDATE_PANELS"
const SHOW_DASHBOARD = "SHOW_DASHBOARD"
const DASHBOARD_SMOOTH_ENTER = "DASHBOARD_SMOOTH_ENTER"
const HIDE_CREATOR = "HIDE_CREATOR"
const CHANGE_INDEX_OF_LAST_PANEL = "CHANGE_INDEX_OF_LAST_PANEL"



const types = {
  ADD_PANEL,
  UPDATE_PANELS,
  SHOW_DASHBOARD,
  DASHBOARD_SMOOTH_ENTER,
  HIDE_CREATOR,
  CHANGE_INDEX_OF_LAST_PANEL
}
export default types