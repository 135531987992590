const CHANGE_PANEL_NAME = "CHANGE_PANEL_NAME"
const CHANGE_PANEL_TYPE = "CHANGE_PANEL_TYPE"
const CHANGE_PANEL_COLOR = "CHANGE_PANEL_COLOR"
const RESET_PANEL_COLOR = "RESET_PANEL_COLOR"
const RESET_PANEL_CUT = "RESET_PANEL_CUT"
const CHANGE_PANEL_CUT = "CHANGE_PANEL_CUT"
const CHANGE_PANEL_TEXT = "CHANGE_PANEL_TEXT"
const CHANGE_FRAMES = "CHANGE_FRAMES"
const EDIT_FRAME_TEXT = "EDIT_FRAME_TEXT"
const CHANGE_ICONS = "CHANGE_ICONS"
const CHANGE_RFID = "CHANGE_RFID"
const SET_BACK_END_REDUCER = "CHANGE_SET_BACK_END_REDUCERICONS"


const types = {
  CHANGE_PANEL_NAME,
  CHANGE_PANEL_TYPE,
  CHANGE_PANEL_COLOR,
  RESET_PANEL_COLOR,
  RESET_PANEL_CUT,
  CHANGE_PANEL_CUT,
  CHANGE_PANEL_TEXT,
  CHANGE_FRAMES,
  EDIT_FRAME_TEXT,
  CHANGE_ICONS,
  CHANGE_RFID,
  SET_BACK_END_REDUCER
}
export default types