const CHANGE_COLOR = "CHANGE_COLOR"
const SET_FRONT_END_REDUCER_COLOR = "SET_FRONT_END_REDUCER_COLOR"
const CHANGE_CUT = "CHANGE_CUT"
const RESET_COLOR = "RESET_COLOR"
const RESET_CUT = "RESET_CUT"

const types = {
  CHANGE_COLOR,
  CHANGE_CUT,
  SET_FRONT_END_REDUCER_COLOR,
  RESET_COLOR,
  RESET_CUT
}
export default types