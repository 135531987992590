const CHANGE_FRAME_FONT = "CHANGE_FRAME_FONT"
const CHANGE_FRAME_FONT_WEIGHT = "CHANGE_FRAME_FONT_WEIGHT"
const CHANGE_FRAME_FONT_INFO = "CHANGE_FRAME_FONT_INFO"
const CHANGE_FRAME_SHAPE = "CHANGE_FRAME_SHAPE"
const CHANGE_RFID_SHAPE = "CHANGE_RFID_SHAPE"
const ADD_NEW_FRAME = "ADD_NEW_FRAME"
const REMOVE_FRAME = "REMOVE_FRAME"
const OVER_FRAME = "OVER_FRAME"
const OVER_FRAME_ALL = "OVER_FRAME_ALL"
const FRAME_HOLDERS = "FRAME_HOLDERS"
const FRAME_HOLDERS_TEMP = "FRAME_HOLDERS_TEMP"
const CHANGE_FRAME_TEXT = "CHANGE_FRAME_TEXT"
const EDIT_FRAME_TEXT = "EDIT_FRAME_TEXT"
const CHANGE_FRAMES_SHAPE_TO_SHARP = "CHANGE_FRAMES_SHAPE_TO_SHARP"
const CHANGE_FRAMES_SHAPE_TO_ROUND = "CHANGE_FRAMES_SHAPE_TO_ROUND"
const OVER_FRAME_RE_RENDER = "OVER_FRAME_RE_RENDER"
const FRAME_TITLE = "FRAME_TITLE"
const ALLOW_FRAME_TITLE = "ALLOW_FRAME_TITLE"
const SET_FRONT_END_REDUCER_FRAME = "SET_FRONT_END_REDUCER_FRAME"



const types = {
  CHANGE_FRAME_FONT,
  CHANGE_FRAME_FONT_WEIGHT,
  CHANGE_FRAME_FONT_INFO,
  CHANGE_FRAME_SHAPE,
  CHANGE_RFID_SHAPE,
  ADD_NEW_FRAME,
  REMOVE_FRAME,
  OVER_FRAME,
  OVER_FRAME_ALL,
  FRAME_HOLDERS,
  FRAME_HOLDERS_TEMP,
  CHANGE_FRAME_TEXT,
  EDIT_FRAME_TEXT,
  CHANGE_FRAMES_SHAPE_TO_SHARP,
  CHANGE_FRAMES_SHAPE_TO_ROUND,
  OVER_FRAME_RE_RENDER,
  FRAME_TITLE,
  ALLOW_FRAME_TITLE,
  SET_FRONT_END_REDUCER_FRAME
}

export default types