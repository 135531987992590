const CHANGE_TEXT_FONT = "CHANGE_TEXT_FONT"
const CHANGE_TEXT_WEIGHT = "CHANGE_TEXT_WEIGHT"
const TEXT_RE_RENDER = "TEXT_RE_RENDER"
const SET_FRONT_END_REDUCER_TEXT = "SET_FRONT_END_REDUCER_TEXT"
const TOGGLE_TEXT_UP = "TOGGLE_TEXT_UP"

const types = {
  CHANGE_TEXT_FONT,
  CHANGE_TEXT_WEIGHT,
  TEXT_RE_RENDER,
  TOGGLE_TEXT_UP,
  SET_FRONT_END_REDUCER_TEXT
}

export default types