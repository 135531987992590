const CHANGE_ICON_HOLDERS = "CHANGE_ICON_HOLDERS"
const ADD_ICON_TO_FAVORITE = "ADD_ICON_TO_FAVORITE"
const REMOVE_ICON_FROM_FAVORITE = "REMOVE_ICON_FROM_FAVORITE"
const IS_ANY_SELECTED = "IS_ANY_SELECTED"
const SET_ARE_THERE_ANY_ICONS = "SET_ARE_THERE_ANY_ICONS"
const SET_FRONT_END_REDUCER_ICON = "SET_FRONT_END_REDUCER_ICON"
const UPDATE_OWN_ICONS = "UPDATE_OWN_ICONS"
const UPDATE_FAVORITE_ICONS = "UPDATE_FAVORITE_ICONS"
const UPDATE_OWN_LOGO = "UPDATE_OWN_LOGO"
const SET_RFID_TYPE = "SET_RFID_TYPE"
const SET_RFID_TEXT = "SET_RFID_TEXT"
const SET_RFID_TEXT_FONT = "SET_RFID_TEXT_FONT"
const SET_RFID_TEXT_FONT_WEIGHT = "SET_RFID_TEXT_FONT_WEIGHT"
const SET_RFID_TEXT_FONT_SIZE = "SET_RFID_TEXT_FONT_SIZE"




const types = {
  CHANGE_ICON_HOLDERS,
  ADD_ICON_TO_FAVORITE,
  REMOVE_ICON_FROM_FAVORITE,
  IS_ANY_SELECTED,
  SET_FRONT_END_REDUCER_ICON,
  UPDATE_OWN_ICONS,
  UPDATE_FAVORITE_ICONS,
  SET_ARE_THERE_ANY_ICONS,
  UPDATE_OWN_LOGO,
  SET_RFID_TYPE,
  SET_RFID_TEXT,
  SET_RFID_TEXT_FONT,
  SET_RFID_TEXT_FONT_WEIGHT,
  SET_RFID_TEXT_FONT_SIZE
}

export default types